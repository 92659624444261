<template>
  <div class="breadcrumb-container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, index) in BreadcrumbData" :key="index">
        <span
          class="Alink"
          @click="goBackUrl(index)"
          >{{ item.title }}</span
        >
        <!-- <span
          class="Alink"
          v-if="item.isLink"
          @click="
            () => {
              $router.push({
                path: item.url
              });
            }
          "
          >{{ item.title }}</span
        >
        <span
          :class="index + 1 == BreadcrumbData.length ? '' : 'spanlink'"
          v-else
          >{{ item.title }}</span
        > -->
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  props: ["BreadcrumbData"],
  methods:{
      goBackUrl(index){
        //   this.$router.go(-1)
          this.$emit('return',index)
      },
  }
};
</script>
<style lang="less" scoped>
.breadcrumb-container {
  background: #fff;
  margin: 3px 1px 20px 16px;
  .Alink {
    &:hover {
      color: #409eff;
    }
    cursor: pointer;
  }
  .spanlink,
  .Alink {
    font-weight: 500;
    color: #00000073;
  }
}
</style>
